import CryptoJS from 'crypto-js';

function convertKeyToWordArray(key) {
  return CryptoJS.enc.Utf8.parse(key); // 等效於 Buffer.from()，處理二進制數據
}

function encrypt(text, key, pbkdf2) {
  const IV_LENGTH = 16; // 生成 16 字節的 IV
  let iv = CryptoJS.lib.WordArray.random(IV_LENGTH); // 隨機生成 IV
  // let iv = convertIvToWordArray('77cff55067dfd1c5aaf584a23dfab144');

  // 將 key 轉換為 WordArray 格式 (Buffer 替代)
  let aesKey = convertKeyToWordArray(key);

  // 如果啟用了 pbkdf2，格式化密鑰
  if (pbkdf2) {
    aesKey = CryptoJS.PBKDF2(key, iv, { keySize: 256 / 32, iterations: 1000 });
  }

  // 使用 AES 進行加密
  const encrypted = CryptoJS.AES.encrypt(text, aesKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  // 返回 IV 和加密數據，並用 `.` 分隔
  return iv.toString(CryptoJS.enc.Hex) + '.' + encrypted.ciphertext.toString(CryptoJS.enc.Hex);
}

export { encrypt };